import React, { FC, ReactNode } from 'react';
import MUIAvatar from '@mui/material/Avatar';
import { styled } from '@mui/material/styles';

interface AvatarProps {
  alt?: string;
  src?: string;
  defaultIcon?: ReactNode;
}

const Avatar: FC<AvatarProps> = ({ alt, src, defaultIcon }) => {
  return (
    <StyledAvatar alt={alt} src={src}>
      {defaultIcon}
    </StyledAvatar>
  );
};

export default Avatar;

const StyledAvatar = styled(MUIAvatar)(() => ({
  width: '48px',
  height: '48px'
}));