import React, { FC } from 'react';
import { colorTokens } from '../../theme/color-tokens';
import ProgressSpinner from '../../components/ProgressSpinner';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';


interface TextButtonProps extends ButtonProps {
  children: any;
  loading?: boolean;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  onClick?: (e?: any) => any;
  padding?: string;
  textColor?: string;
  
}

const TextButton: FC<TextButtonProps> = ({
  children,
  loading,
  disabled,
  size = 'large',
  onClick,
  padding,
  ...props
}) => {
  return (
    <StyledTextButton
      disabled={disabled}
      variant="text"
      disableElevation
      disableFocusRipple
      disableTouchRipple
      size={size}
      padding={padding}
      onClick={() => {
        onClick?.();
      }}
      {...props}
    >
      {loading ? (
        <ProgressSpinner size={size === 'large' ? '24px' : '20px'} color={colorTokens.iconPrimary} />
      ) : (
        children
      )}
    </StyledTextButton>
  );
};

export default TextButton;

const StyledTextButton = styled(Button)<TextButtonProps>(({ disabled, size, padding, textColor }) => ({
  boxSizing: 'border-box',
  backgroundColor: `transparent`,
  borderRadius: '8px',
  padding: '0 24px',
  height: '56px',
  color: `${textColor || '#fff'} !important`,
  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: 'normal',
  textTransform: 'none',

  ...(size === 'medium' && {
    padding: '0 16px',
    height: '40px',
    fontSize: '14px'
  }),

  ...(size === 'small' && {
    padding: '0 8px',
    height: '32px',
    fontSize: '12px'
  }),

  '&:hover': {
    backgroundColor: colorTokens.backgroundHover
  },

  '&:focus': {
    backgroundColor: colorTokens.backgroundFocus,
    boxShadow: '0px 0px 0px 3px rgba(255, 255, 255, 0.01)'
  },

  '&:active': {
    backgroundColor: colorTokens.backgroundActive,
    boxShadow: 'none'
  },

  '&:disabled': {
    backgroundColor: 'transparent',
    opacity: '0.5'
  }
}));