import React, { FC } from 'react';
import { useRouteChange } from '@/lib/hooks/routing';
import PageLoading from '@/componentsV2/loading/PageLoading';

interface RoutingLoaderLayoutProps {
  children?: any;
  isSplitScreen?: boolean;
}

const RoutingLoaderLayout: FC<RoutingLoaderLayoutProps> = ({ children, isSplitScreen }) => {
  const { routingStarted, longWaitTime } = useRouteChange();

  return (
      <>
        <PageLoading open={routingStarted} longWaitTime={longWaitTime} isSplitScreen={isSplitScreen}/>
        {children}
      </>
  );
};

export default RoutingLoaderLayout;