import React, { FC } from 'react';
import Backdrop from '@mui/material/Backdrop';
import Stack from '@mui/material/Stack';
import { createTheme, ThemeProvider, styled } from '@mui/material/styles';
import { colors } from '../../theme/colors';
import ProgressSpinner from '../../components/ProgressSpinner';
import { Paragraph } from '../../theme/text-variants';

const theme = createTheme({});

interface LoadingOverlayProps {
  open: boolean;
  handleClose?: () => any;
  loadingText?: string;
  isSplitScreen?: boolean;
}

const LoadingOverlay: FC<LoadingOverlayProps> = ({ open, handleClose, loadingText, isSplitScreen }) => {
  return (
    <ThemeProvider theme={theme}>
      <StyledBackdrop isSplitScreen={isSplitScreen} open={open} onClick={handleClose} >
        <Stack>
          <ProgressSpinner color={colors.white} size="64px" />
          {loadingText && <Paragraph pt="16px" size="md" color={colors.white}>{loadingText}</Paragraph>}
        </Stack>
      </StyledBackdrop>
    </ThemeProvider>
  );
};

export default LoadingOverlay;

const StyledBackdrop = styled(Backdrop)<{isSplitScreen?: boolean}>(({isSplitScreen}) => ({
  zIndex: '5000',
  height: isSplitScreen ? "50vh" : "inherit",
}));