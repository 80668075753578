import Divider from '@mui/material/Divider';
import Stack from '@mui/material/Stack';
import { Overline } from '../../../theme/text-variants';
import React, { FC, ReactNode } from 'react';
import { useRouter } from 'next/router';
import StoreView from './StoreView';
import StoresList from './StoresList';
import { User } from '../../../api';
import List from '@mui/material/List';
import ListItem from '../../lists/ListItem';
import Settings from '@/img/settings.svg';

export type CurrentShop = {
  id?: string;
  slug?: string;
  name?: string;
  email?: string;
  profilePicUrl?: string;
};

interface SellerViewProps {
  shops: any;
  user?: User;
}

const SellerView: FC<SellerViewProps> = ({ shops, user }) => {
  const { query, push } = useRouter();

  let content: ReactNode = (
    <Stack>
      <Divider />
      <List>
        <ListItem
          size="large"
          startIcon={<Settings />}
          option="Account Settings"
          variant="link"
          onClick={() => push('/settings')}
        />
      </List>
      <Divider />
      <Stack padding="16px">
        <Stack height="32px" justifyContent="center">
          <Overline size="xs">STORES</Overline>
        </Stack>
        <StoresList shops={shops} />
      </Stack>
    </Stack>
  );

  if (query?.shopSlug) {
    const filteredStoresList = shops.filter((shop: any) => shop.slug !== query?.shopSlug);
    content = <StoreView shops={filteredStoresList} user={user} />;
  }

  return <Stack>{content}</Stack>;
};

export default SellerView;