import React, { FC } from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import { colors } from '../../theme/colors';
import ProgressSpinner from '../../components/ProgressSpinner';
import { colorTokens } from '../../theme/color-tokens';

export interface OutlineButtonProps extends Omit<ButtonProps, 'color'> {
  loading?: boolean;
  disabled?: boolean;
  onClick?: (e?: any) => any;
  size?: 'large' | 'medium' | 'small';
  fullWidth?: boolean;
  color?: string;
  borderColor?: string;
  loaderColor?: string;
  width?: string;
  minWidth?: string;
  active?: boolean;
  padding?: string;
}

const OutlineButton: FC<OutlineButtonProps> = ({
  children,
  loading,
  disabled,
  onClick,
  size,
  fullWidth,
  color,
  borderColor,
  loaderColor = colors.purple500,
  width,
  minWidth,
  active = false,
  padding,
  ...props
}) => {
  return (
    <StyledOutlineButton
      {...props}
      active={active}
      minWidth={minWidth}
      fullWidth={fullWidth}
      size={size}
      disabled={disabled}
      padding={padding}
      width={width}
      onClick={(e) => {
        e.preventDefault();
        if (!loading) {
          onClick?.();
        }
      }}
      disableElevation
      disableFocusRipple
      disableTouchRipple
      disableRipple
      style={{
        color: color ?? colorTokens.textPrimary,
        borderColor: borderColor
      }}
    >
      {loading ? (
        <ProgressSpinner size={size === 'medium' || size === 'large' ? '24px' : '16px'} color={loaderColor} />
      ) : (
        children
      )}
    </StyledOutlineButton>
  );
};

export default OutlineButton;

const StyledOutlineButton = styled(Button)<OutlineButtonProps>(({ disabled, size, color, active, padding }) => ({
  backgroundColor: active ? colors.purple200 : 'white',
  border: `1px solid ${colors.purple500}`,
  borderRadius: '16px',
  padding: padding ? padding : '0 16px',
  minWidth: '200px',
  height: '56px',

  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '600',
  lineHeight: 'auto',
  textTransform: 'none',
  opacity: disabled ? '0.5' : '1',

  ...(size === 'medium' && {
    height: '40px',
    minWidth: '100px',
    borderRadius: '8px',
    fontSize: '14px'
  }),

  ...(size === 'small' && {
    padding: '0 12px',
    minWidth: 'fit-content',
    height: '32px',
    borderRadius: '8px',
    fontSize: '12px'
  }),

  '&:hover': {
    backgroundColor: colors.purple100
  },

  '&:focus': {
    boxShadow: `0 0 0 3px ${colors.purple100}`,
    backgroundColor: 'inherit'
  },

  '&:active': {
    backgroundColor: colors.purple200,
    boxShadow: 'none'
  }
}));
