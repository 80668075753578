import React, { FC, ReactNode } from 'react';
import MUIListItem, { ListItemProps as MUIListItemProps } from '@mui/material/ListItem';
import { styled } from '@mui/material/styles';
import ListItemIcon from '@mui/material/ListItemIcon';
import { Paragraph } from '../../theme/text-variants';
import Stack from '@mui/material/Stack';
import Radio from '../buttons/Radio';

import ChevronRight from '@/img/chevron-right.svg';
import { colorTokens } from '../../theme/color-tokens';
import { colors } from '../../theme/colors';
import OutlineButton from '../buttons/OutlineButton';

interface ListItemProps extends Omit<MUIListItemProps, 'selected'> {
  startIcon: ReactNode;
  option: string;
  supportingText?: string;
  buttonText?: string;
  disabled?: boolean;
  size?: 'small' | 'medium' | 'large';
  variant?: 'default' | 'link' | 'radio' | 'stat' | 'toggle' | 'button' | 'dropdown';
  selected?: boolean;
  onSelect?: () => void;
  stat?: string;
  //   endIcon?: ReactNode;
}

const ListItem: FC<ListItemProps> = ({
  startIcon,
  option,
  supportingText,
  buttonText,
  disabled,
  size = 'large',
  variant = 'default',
  selected = false,
  onChange,
  onClick,
  stat,
  ...props
}) => {
  let endIcon: ReactNode = <></>;

  //   TODO: Add icons for the other variant types
  switch (variant) {
    case 'radio':
      endIcon = <Radio checked={selected} onChange={(e: any) => onChange?.(e)} />;
      break;
    case 'link':
      endIcon = <ChevronRight />;
      break;
    case 'button':
      endIcon = <OutlineButton size="small" onClick={(e) => onClick?.(e)} disabled={disabled}>{buttonText}</OutlineButton>
      break;
    default:
      break;
  }

  return (
    <StyledListItem
      option={option}
      size={size}
      variant={variant}
      selected={selected}
      {...props}
      onClick={(e: any) => {
        if (variant !== "button") {
          onClick?.(e)
        }
      }}
    >
      <StyledListItemIcon>{startIcon}</StyledListItemIcon>
      <Stack flexGrow={1}>
        <Stack>
          <Paragraph
            size={size === 'small' ? 'sm' : 'md'}
            fontWeight={size === 'medium' ? 'regular' : 'bold'}
            color={colors.black}
          >
            {option}
          </Paragraph>
          {
            supportingText && (
              <Paragraph size="xs">{supportingText}</Paragraph>
            )
          }
        </Stack>
      </Stack>
      {stat && (
        <Paragraph size="md" fontWeight="bold">
          {stat}
        </Paragraph>
      )}
      <StyledListItemIcon
        onClick={(e: any) => {
          if (variant !== 'link') {
            e.preventDefault();
            e.stopPropagation();
          }
        }}
      >
        {endIcon}
      </StyledListItemIcon>
    </StyledListItem>
  );
};

export default ListItem;

const StyledListItem = styled(MUIListItem)<Omit<ListItemProps, 'startIcon' | 'text'>>(
  ({ size, variant, selected, disabled }) => ({
    display: 'flex',
    gap: '24px',

    '& svg': {
      width: "20px",
      height: "20px"
    },

    ...(size === 'large' && {
      height: '56px',
      '& svg': {
        width: "24px",
        height: "24px"
      },
    }),

    ...(size === 'medium' && {
      height: '52px'
    }),

    ...(size === 'small' && {
      height: '48px !important'
    }),

    '& .MuiListItemIcon-root': {
      color: colors.black
    },

    '&:hover': {
      cursor: 'pointer',
      backgroundColor: disabled ? colors.white : `${colorTokens.itemSubtleHover} !important`
    },

    ...(selected && {
      backgroundColor: `${colorTokens.backgroundSelected} !important`,

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: `${colorTokens.itemHover} !important`
      }
    }),

  })
);

const StyledListItemIcon = styled(ListItemIcon)(() => ({
  minWidth: '0'
}));