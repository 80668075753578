import Button, { ButtonProps } from '@mui/material/Button';
import React, { FC } from 'react';
import { colorTokens } from '../../theme/color-tokens';
import { colors } from '../../theme/colors';
import { styled } from '@mui/material/styles';

const CreateLiveShowButton: FC<ButtonProps> = ({ children, ...props }) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export default CreateLiveShowButton;

const StyledButton = styled(Button)(() => ({
  borderRadius: '16px',
  height: '56px',
  minWidth: '200px',
  color: colors.white,
  backgroundColor: `${colorTokens.textAlert} !important`,

  fontFamily: 'Poppins',
  fontSize: '16px',
  fontWeight: '500',
  lineHeight: 'auto',
  textTransform: 'none'
}));